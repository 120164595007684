.app {
  text-align: center;
}

.container {
  background-color: #ae6bf6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 20vmin;
  pointer-events: none;
}

.logo-text {
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 1rem;
}

.privacy-text {
  font-size: calc(10px + 2vmin);
  color: white;
  margin-left: 2rem;
  margin-right: 2rem;
}

.link {
  font-size: calc(10px + 2vmin);
  margin-top: 1rem;
  margin-bottom: 2rem;
}



